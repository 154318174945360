import { createApp } from 'vue'
// import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jQuery from 'jquery'
import encoding from 'encoding-japanese'
import moment from "moment"
import Swal from 'sweetalert2'
import { createHead } from '@vueuse/head'
import VueGtag from "vue-gtag"

////////////////////////////////////
// 多言語化 {{$t("test.test1")}}
////////////////////////////////////
const messages = {
    ja: {
        config: {
            domain: `${process.env.VUE_APP_API_DOMAIN}`,
            aws_api_domain: `${process.env.VUE_APP_AWS_API_DOMAIN}`,
            aws_socket_domain: `${process.env.VUE_APP_AWS_SOCKET_DOMAIN}`,
            x_api_key: `${process.env.VUE_APP_X_API_KEY}`,
            header_path: `${process.env.VUE_APP_HEADER_IMAGE_PATH}`,
            favicon_path: `${process.env.VUE_APP_FAVICON_PATH}`,
            // check_person_num_endpoint: `${process.env.VUE_APP_CHECK_PERSON_NUM_ENDPOINT}`,
            api_token_cookie_name: 'api_token',
            user_name_cookie_name: 'user_name',
            facility_name_cookie_name: 'facility_name',
            thumbnail_size: '2000000'
        },
        errors: {
            error: 'エラー',
            fetch_fail: '予期せぬエラーが発生しました。時間をおいてやり直すか、管理者にご連絡ください。',
            expire_auth: 'failed login.',
        }
    }
}

const i18n = createI18n({
    locale: 'ja', // set locale
    messages, // set locale messages)
})

const head = createHead()

////////////////////////////////////
// GA 導入
////////////////////////////////////
// if (process.env.NODE_ENV === "production") {
    // Vue.use(
    //     VueGtag,
    //     {
    //         config: { id: "G-PFGWEPL635" },
    //     },
    //     router
    // );
// } else {
//     createApp(App).use(router);
// }

createApp(App)
    .use(
        VueGtag,
        {
            config: { id: "G-PFGWEPL635" },
        }
    )
    .use(
        router,
        VueAxios,
        axios,
        jQuery,
        encoding,
        moment,
        Swal
    )
    .use(i18n)
    .use(head)
    .mount('#app')
