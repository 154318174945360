import { createRouter, createWebHistory } from 'vue-router'
import AdminApp from '../AdminApp.vue'
import MasterApp from '../MasterApp.vue'
// import App from '../App.vue'

const routes = [
  {
    path: '/admin',
		component: MasterApp,
		children: [
      /*********** 運営管理者 *************/
      {
        // ログイン
        path: 'login',
        name: 'MasterLogin',
        meta: {
          title: '運営管理者ログイン',
        },
        component: () => import('../views/master/Login.vue')
      },
      {
        // 申し込み管理
        path: 'entry',
        name: 'EntryIndex',
        meta: {
          title: '申し込み管理',
        },
        component: () => import('../views/master/entry/Index.vue')
      },
      {
        // サイト管理
        path: 'site',
        name: 'SiteIndex',
        meta: {
          title: 'サイト管理',
        },
        component: () => import('../views/master/site/Index.vue')
      },
      {
        // サイト詳細
        path: 'site/:id',
        name: 'SiteShow',
        props: true,
        meta: {
          title: 'サイト詳細',
        },
        component: () => import('../views/master/site/Show.vue')
      },
      {
        // サイト作成
        path: 'site/create',
        name: 'SiteCreate',
        props: true,
        meta: {
          title: 'サイト作成',
        },
        component: () => import('../views/master/site/Create.vue')
      },
      {
        // ルーム管理
        path: 'room',
        name: 'RoomIndex',
        meta: {
          title: 'ルーム管理',
        },
        props: true,
        component: () => import('../views/master/room/Index.vue')
      },
      {
        // ルーム詳細
        path: 'room/:id',
        name: 'RoomShow',
        meta: {
          title: 'ルーム詳細',
        },
        component: () => import('../views/master/room/Show.vue')
      },
      {
        // ルーム作成
        path: 'room/create',
        name: 'RoomCreate',
        meta: {
          title: 'ルーム作成',
        },
        props: true,
        component: () => import('../views/master/room/Create.vue')
      },
    ]
  },
	{
		path: '/:directory/admin',
		component: AdminApp,
		children: [
      /*********** 企業管理者 *************/
      {
        // 社員管理
        path: 'employee',
        name: 'EmployeeIndex',
        meta: {
          title: '社員管理',
        },
        component: () => import('../views/admin/employee/Index.vue')
      },
      {
        // 部署管理
        path: 'department',
        name: 'DepartmentIndex',
        meta: {
          title: '部署管理',
        },
        component: () => import('../views/admin/department/Index.vue')
      },
      {
        // ゲスト管理
        path: 'guest',
        name: 'GuestIndex',
        meta: {
          title: 'ゲスト管理',
        },
        component: () => import('../views/admin/guest/Index.vue')
      },
      {
        // オフィス管理
        path: 'office',
        name: 'OfficeIndex',
        meta: {
          title: 'オフィス管理',
        },
        component: () => import('../views/admin/office/Index.vue')
      },
      {
        // オフィス管理>レイアウト編集
        path: 'office/layout/:id',
        name: 'EditLayout',
        props: true,
        meta: {
          title: 'オフィス管理',
        },
        component: () => import('../views/admin/office/EditLayout.vue')
      },
      {
        // お知らせ管理
        path: 'information',
        name: 'InformationIndex',
        meta: {
          title: 'お知らせ管理',
        },
        component: () => import('../views/admin/information/Index.vue')
      },
      {
        // お知らせ管理
        path: 'log',
        name: 'LogIndex',
        meta: {
          title: 'ログ管理',
        },
        component: () => import('../views/admin/log/Index.vue')
      },
      {
        // サイト管理
        path: 'site_management',
        name: 'SiteManagement',
        meta: {
          title: 'サイト管理',
        },
        component: () => import('../views/admin/site/Index.vue')
      },
      {
        // 利用状況
        path: 'usage',
        name: 'Usage',
        meta: {
          title: '利用状況',
        },
        component: () => import('../views/admin/usage/Index.vue')
      },
      {
        // 契約
        path: 'agreement',
        name: 'Agreement',
        meta: {
          title: '契約',
        },
        component: () => import('../views/admin/agreement/Index.vue')
      },
    ]
  },

  /*********** 未ログイン（企業対象なし） *************/
  {
    // 予約のチェック画面（会員登録なしで会議に参加する場合）
    path: '/trial_entry',
    name: 'TrialEntry',
    meta: {
      title: 'トライアル利用申し込み',
    },
    component: () => import('../views/TrialEntry.vue')
  },

  /*********** 未ログイン（特定の企業） *************/
  {
    // 予約のチェック画面（会員登録なしで会議に参加する場合）
    path: '/:directory/reserve_check/:id',
    name: 'ReserveCheck',
    meta: {
      title: '会議室入室エラー',
    },
    component: () => import('../views/virtual_office/ReserveCheck.vue')
  },
  {
    // 新規登録画面（ユーザー仮登録済）
    path: '/:directory/guest_register/:id',
    name: 'GuestMainRegister',
    meta: {
      title: '新規ユーザー登録入力',
    },
    component: () => import('../views/GuestRegister.vue')
  },
  {
    // 新規登録画面
    path: '/:directory/guest_register',
    name: 'GuestRegister',
    meta: {
      title: '新規ユーザー登録入力',
    },
    component: () => import('../views/GuestRegister.vue')
  },
  {
    // 社員ログイン
    path: '/:directory/login',
    name: 'Login',
    meta: {
      title: 'Login',
    },
    component: () => import('../views/Login.vue')
  },
  {
    // 一時ゲストログイン
    path: '/:directory/Guest_login',
    name: 'GuestLogin',
    meta: {
      title: 'Login',
    },
    component: () => import('../views/GuestLogin.vue')
  },
  {
    path: "/:directory/logout",
    name: 'logout',
    meta: {
      title: 'logout',
    },
    component: () => import('../views/logout.vue')
  },
  {
    // パスワード忘れ
    path: '/:directory/password_forgot',
    name: 'PasswordForgot',
    meta: {
      title: 'パスワード忘れ',
    },
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    // パスワード再登録
    path: '/:directory/password_update/:id',
    name: 'PasswordUpdate',
    props: true,
    meta: {
      title: 'パスワード再登録',
    },
    component: () => import('../views/PasswordUpdate.vue')
  },
  {
    // パスワード再設定完了
    path: '/:directory/password_update_comp',
    name: 'PasswordUpdateComp',
    meta: {
      title: 'パスワード再登録 完了',
    },
    component: () => import('../views/PasswordUpdateComp.vue')
  },

  /*********** 社員・ゲスト *************/

  {
    // TOPページ
    path: '/:directory/virtual_office',
    name: 'VirtualOffice',
    meta: {
      title: 'TOP',
    },
    component: () => import('../views/virtual_office/VirtualOffice.vue')
  },
  {
    // 社員検索
    path: '/:directory/virtual_office/employee',
    name: 'Employee',
    meta: {
      title: '社員検索',
    },
    component: () => import('../views/virtual_office/Employee.vue')
  },
  {
    // 会議室予約（ID指定の場合）
    path: '/:directory/virtual_office/meeting_room/reservation/:id',
    name: 'MeetingRoomReservation',
    props: true,
    meta: {
      title: '会議室予約',
    },
    component: () => import('../views/virtual_office/MeetingReservation.vue')
  },
  {
    // 会議室予約
    path: '/:directory/virtual_office/meeting_room/reservation',
    name: 'MeetingReservation',
    props: true,
    meta: {
      title: '会議室予約',
    },
    component: () => import('../views/virtual_office/MeetingReservation.vue')
  },
  {
    // プロフィール
    path: '/:directory/my_page',
    name: 'MyPage',
    props: true,
    meta: {
      title: 'マイページ',
    },
    component: () => import('../views/profile/MyPage.vue')
  },
  {
    // プロフィール
    path: '/:directory/get_cyzy/:id',
    name: 'GetCyzy',
    props: true,
    meta: {
      title: 'cyzyNmame取得',
    },
    component: () => import('../views/virtual_office/GetCyzy.vue')
  },

  /*********** リダイレクト設定 *************/
  // {
  //   //リダイレクトの設定
  //   path: '/',
  //   redirect: '/virtual_office'
  // },

  /*********** Not Found *************/
  {
    path: "/not_found",
    name: 'NotFoundError',
    meta: {
      title: 'Not Found',
    },
    component: () => import('../views/NotFound.vue')
  },
  {
    path: "/:directory(.*)",
    name: 'NotFoundError',
    meta: {
      title: 'Not Found',
    },
    component: () => import('../views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
