<template>
	<span>
		<div class="sidebar" :class="isCollapsed == true ? 'collapsed' : ''">
			<div class="sidebar__inner">
				<div class="logo-area">
					<img :src="this.$t('config.header_path')+'academia-logo.png'">
				</div>
				<ul>
					<!-- <li class="sidebar_items" :class="activeMenu == 'entry' ? 'is-active' : ''" v-on:click="goPage('entry')">申し込み管理</li> -->
					<li class="sidebar_items" :class="activeMenu == 'site' ? 'is-active' : ''" v-on:click="goPage('site')">サイト情報管理</li>
					<li class="sidebar_items" :class="activeMenu == 'room' ? 'is-active' : ''" v-on:click="goPage('room')">ルーム管理</li>
				</ul>
			</div>
		</div>
		<div class="master_header" :class="isCollapsed == true ? 'expand' : ''">
			<ul class="master_header__items">
				<li>
					<input type="checkbox" id="menu-btn-check" v-on:click="toggleMenu">
					<label for="menu-btn-check" class="menu-btn"><span></span></label>
				</li>
				<li class="header_logout">
					<p class="header_top_btn">
						<a class="header_top_btn_logout" v-on:click="sendLogout">ログアウト</a>
					</p>
				</li>
			</ul>
		</div>
	</span>
</template>

<script>
import jQuery from 'jquery'
import Swal from "sweetalert2";
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default defineComponent ({
	name: 'MasterHeader',

	props: ['title', 'isCollapsed'],

	data() {
		return {
			activeMenu: null,
			loginUser: {
				type: null,
				user_id: null,
				user_name: null
			},
			is_master: false
		}
	},

	methods: {
		// メタの設定
		setMeta: function () {
			const siteData = reactive({
				favicon: 'metago.ico',
			})
			useHead({
				title: computed(() => 'METAGO運営管理 | ' + this.$route.meta.title),
				link: [
					{
						rel: 'icon',
						href: computed(() => this.$t('config.favicon_path') + siteData.favicon)
					}
				]
			})
		},

		/** ログアウト */
		sendLogout: function () {
			jQuery('#loader_bg_wh').fadeIn(50);
			const param = this;
			setTimeout(function() {
				param.$router.push({name: 'MasterLogin'});
			}, 300);
    },

		toggleMenu: function () {
			this.$emit('toggleMenu');
		},


		/** ログイン済みチェック（リロード時など用） */
		access: function () {
			const api_token = window.giulietta_getCookie(`${this.$t('config.api_token_cookie_name')}`);

			const params = {
				directory: 'admin'
			};

			const queryParams = new URLSearchParams(params);

			const url = new URL(
				`${this.$t("config.domain")}/api/v1/access`
			);

			let headers = {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${api_token}`
			};

			fetch(url + '?' + queryParams, {
				method: "GET",
				headers: headers,
				credentials: 'include'
			})

			.then(response => {
				if (!response.ok) {
				if (response.status == 401 || response.status == 403) {
					// login エラー
          window.giulietta_logout(this.$t("config.domain"), this.$t('config.api_token_cookie_name'), this.loginUser.user_id, 'admin');
        } else if (response.status == 500) {
					// 予期せぬ エラー
					Swal.fire({
						title: this.$t('errors.error'),
						text: this.$t('errors.fetch_fail'),
						icon: 'error',
						confirmButtonText: 'OK'
					})
				}
				return false;
				}
				return response.json();
			})

			.then(json => {
				console.log(json);
				if (json === false) {
					return false;
				}
				this.loginUser.id = json['data']['user_id'];
				this.loginUser.name = json['data']['user_name'];
				this.loginUser.type = json['data']['type'];
				// 権限チェック
				if (json['data']['type'] == 'master') {
					this.is_master = true;
				} else {
					this.is_master = false;
				}

				// 運営管理者ページは運営管理者でしかアクセスできない＝バーチャルオフィストップページに移行
				if (this.$route.path.indexOf('/admin') != -1 && this.is_master == false) {
					this.toVirtualOffice();
				}
				this.$emit('getLoginUser', this.loginUser);
			})

			.catch((e) => {
				console.log(e);
				Swal.fire({
					title: this.$t('errors.error'),
					text: this.$t('errors.fetch_fail'),
					icon: 'error',
					confirmButtonText: 'OK'
				})
				jQuery('#loader_bg_wh').fadeIn(50);
				const param = this;
				setTimeout(function() {
					param.$router.push({name: 'MasterLogin'});
				}, 300);
			})

			.finally(() => {
				// jQuery('#loader_bg_wh').hide();
			});
		},

		checkMenu: function() {
			let pathList = this.$route.path.split('/');
			if (pathList.findIndex((path) => path == 'entry') != -1) {
				this.activeMenu = 'entry';
			} else if (pathList.findIndex((path) => path == 'site') != -1) {
				this.activeMenu = 'site';
			} else if (pathList.findIndex((path) => path == 'room') != -1) {
				this.activeMenu = 'room';
			}
		},

		goPage: function (page) {
			let pageName = 'EntryIndex';
			switch (page) {
				case 'entry':
					pageName = 'EntryIndex';
					break;
				case 'site':
					pageName = 'SiteIndex';
					break;
				case 'room':
					pageName = 'RoomIndex';
					break;
				default:
					pageName = 'EntryIndex';
					break;
			}
			if (this.$route.name != pageName) {
				jQuery('#loader_bg_wh').fadeIn(50);
				const param = this;
				setTimeout(function() {
					param.$router.push({name: pageName});
				}, 300);
			}
		}
	},

	watch: {
		$route() {
			this.checkMenu();
		}
	},

	mounted() {
		// meta 設定
		this.setMeta();
		// アクティブのメニュー設定
		this.checkMenu();
		if (this.$route.name != 'MasterLogin') {
			this.access();
		}
		jQuery('#app').fadeIn(50);
	},

	beforeUnmount() {
		jQuery('#loader_bg_wh').fadeIn(50);
		jQuery('#app').hide();
	},


})
</script>

<style src="../../public/css/style_master.css" scoped></style>
