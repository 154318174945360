<template>
	<span>
		<MasterHeader
			v-show="$route.name != 'MasterLogin'"
			:isCollapsed="isCollapsed"
			@toggleMenu="toggleMenu"
		/>
		<main id="main" :style="isCollapsed == false && windowWidth >= 992 && this.$route.name != 'MasterLogin' ? 'margin-left:210px' : ''">
			<router-view/><!-- @/viewsの中をURLに従って表示 -->
		</main>
	</span>
</template>

<script>
// modules
import jQuery from 'jquery';
import MasterHeader from '@/components/MasterHeader.vue';

export default {
	components: {
		MasterHeader,
	},

	data() {
		return {
			isCollapsed: jQuery(window).width() >= 992 ? false : true,
			windowWidth: jQuery(window).width()
		}
	},

	methods: {
		toggleMenu: function () {
			if (this.isCollapsed == false) {
				this.isCollapsed = true;
			} else {
				this.isCollapsed = false;
			}
		},

        calculateWindowWidth: function () {
			this.windowWidth = jQuery(window).width();
			if (this.windowWidth >= 992) {
				this.isCollapse = false;
			} else {
				this.isCollapse = true;
			}
		},
	},

    mounted() {
        window.addEventListener('resize', this.calculateWindowWidth);
		jQuery('.wrapper').addClass('master-wrapper');
    }
}
</script>

<!--<style>-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--#nav {-->
<!--  padding: 30px;-->
<!--}-->

<!--#nav a {-->
<!--  font-weight: bold;-->
<!--  color: #2c3e50;-->
<!--}-->

<!--#nav a.router-link-exact-active {-->
<!--  color: #42b983;-->
<!--}-->
<!--</style>-->
